<template>
  <div class="main">
    <div class="user content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Candidates</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Add candidate</h4>
          <div class="add-outline">
            <a-button type="primary">
              <router-link :to="'/client/candidates'+loginAs" v-if="!groupId"><ArrowLeftOutlined /> Back</router-link>
              <router-link :to="'/client/candidate_group/add/'+groupId+loginAs" v-if="groupId">
                Back
              </router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <AddCandidate :clientId="clientId" :groupId="groupId" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import AddCandidate from '@/components/client/candidate/AddCandidate.vue'
import { useRoute } from 'vue-router'

import commonServices from '../../../services/common'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    AddCandidate
  },
  setup () {
    const route = useRoute()
    const groupId = ref()
    const clientId = ref<string>(commonServices.getClientId())
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    onMounted(() => {
      groupId.value = route.params.group
    })
    return {
      clientId,
      groupId,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.user {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      color: #646464;
      font-family: "TT Norms Pro Medium";
      margin: 0;
    }
    .add-outline {
      float: right;
      .ant-btn {
        background-color: transparent;
        border-color: #38B6FF;
        color: #38B6FF;
        border-radius: 5px;
        font-family: "TT Norms Pro Medium";
        text-shadow: none;
        box-shadow: none;
        a {
          color: #38B6FF;
        }
      }
    }
  }
}
</style>
